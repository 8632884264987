import React from "react"
import styled from "@emotion/styled"

import Section from "../shared/Section"
import { CheckMark } from "../../assets/icons"

const guides = [
  {
    name: `French`,
    url: `https://frenchstudyguide.com`,
    updated: true,
    status: `active`,
  },
  { name: `Spanish`, url: `https://spanishstudyguide.com`, status: `active` },
  { name: `Italian`, url: `https://italianstudyguide.com`, status: `active` },
  {
    name: `Portuguese`,
    url: `https://portuguesestudyguide.com`,
    status: `active`,
  },
  { name: `Dutch`, url: `https://dutchstudyguide.com`, status: `active` },
  { name: `German`, url: `https://germanstudyguide.co`, status: `active` },
  { name: `Chinese`, url: `https://chinesestudyguide.com`, status: `active` },
  { name: `Japanese`, url: `https://japanesestudyguide.com`, status: `active` },
  { name: `Korean`, url: `https://koreanstudyguide.com`, status: `dev` },
  { name: `Russian`, url: `https://russianstudyguide.com`, status: `dev` },
  { name: `Ukrainian`, url: `https://ukrainianstudyguide.com`, status: `dev` },
  { name: `Swedish`, url: `https://swedishstudyguide.com`, status: `dev` },
  { name: `Norwegian`, url: `https://norwegianstudyguide.com`, status: `dev` },
  { name: `Danish`, url: `https://danishstudyguide.com`, status: `dev` },
  { name: `Greek`, url: `https://greekstudyguide.com`, status: `dev` },
  { name: `Hebrew`, url: `https://hebrewstudyguide.com`, status: `dev` },
  { name: `Turkish`, url: `https://turkishstudyguide.com`, status: `dev` },
  { name: `Polish`, url: `https://polishstudyguide.com`, status: `dev` },
  { name: `Arabic`, url: `https://arabicstudyguide.com`, status: `dev` },
  { name: `Hindi`, url: `https://hindistudyguide.com`, status: `dev` },
  {
    name: `Vietnamese`,
    url: `https://vietnamesestudyguide.com`,
    status: `dev`,
  },
]

const StudyGuide = () => (
  <>
    <Section first={true}>
      <h2>Study Guides</h2>
      <p>
        Study guides are supplemental websites that feature hundreds of
        vocabulary words, common situational phrases, flashcards, text-to-speech
        audio, and verb conjugations.
      </p>
      <p>
        I started developing the study guides on WordPress in 2016 and I plan to
        update them with better content and newer web technologies this year. To
        track the progress of this initiative, see below:
      </p>
    </Section>
    <div style={{ overflow: `hidden` }}>
      <div style={{ overflowX: `scroll` }}>
        <Table>
          <thead>
            <tr>
              <th>Language</th>
              <th>URL</th>
              <th>Updated</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {guides.map(guide => (
              <tr key={guide.name}>
                <td>{guide.name}</td>
                <td>
                  <a href={guide.url} target="_blank" rel="noopener noreferrer">
                    {guide.url}
                  </a>
                </td>
                <td>{guide.updated ? <CheckMark /> : ""}</td>
                <td>{guide.status === "active" ? <CheckMark /> : ""}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  </>
)

const Table = styled.table`
  border: 1px solid gainsboro;
  margin-top: 60px;
  font-size: 1.2em;

  thead {
    background: #fbfbfb;
    border-bottom: 1px solid gainsboro;
  }

  td {
    border-bottom: 1px solid gainsboro;
  }

  a {
    text-decoration: underline;
  }

  tr td:last-child,
  tr th:last-child,
  tr td:nth-of-type(3),
  tr th:nth-of-type(3) {
    text-align: center;
  }

  svg {
    height: 32px;
  }
`

export default StudyGuide
