import React from "react"
import styled from "@emotion/styled"
// Images
import gatsby from "../../assets/images/gatsby.png"
import spring from "../../assets/images/spring.png"
import lingui from "../../assets/images/lingui.png"
import airtable from "../../assets/images/airtable.png"
import unsplash from "../../assets/images/unsplash.png"
import netlify from "../../assets/images/netlify.png"

import { LinkIcon, GithubIcon } from "../../assets/icons"
import Section from "../shared/Section"
import { colors, breakpoints } from "../../utils/styles"

const tools = [
  {
    name: "GatsbyJS",
    link: "https://github.com/gatsbyjs/gatsby",
    type: "oss",
    image: gatsby,
    description: "",
  },
  {
    name: "React Spring",
    link: "https://github.com/react-spring/react-spring",
    type: "oss",
    image: spring,
    description: "",
  },
  {
    name: "LinguiJS",
    link: "https://github.com/lingui/js-lingui",
    type: "oss",
    image: lingui,
    description: "",
  },
  {
    name: "Airtable",
    link: "https://airtable.com",
    type: "website",
    image: airtable,
    description: "",
  },
  {
    name: "Netlify",
    link: "https://www.netlify.com/",
    type: "website",
    image: netlify,
    description: "",
  },
  {
    name: "Unsplash",
    link: "https://unsplash.com/",
    type: "website",
    image: unsplash,
    description: "",
  },
]
const Credits = () => {
  return (
    <>
      <Section first={true}>
        <h2>Credits</h2>
        <p>
          Language Guide and the corresponding Study Guide sites are not
          currently open source, but they use a few amazing libraries and
          services that deserve recognition:
        </p>
      </Section>
      <CreditList>
        {tools.map(tool => (
          <li key={tool.name}>
            <img src={tool.image} alt={tool.name} />
            <div>
              <h3>{tool.name}</h3>
              <a href={tool.link} target="_blank" rel="noopener noreferrer">
                {tool.type === "oss" ? (
                  <>
                    <GithubIcon /> Source
                  </>
                ) : (
                  <>
                    <LinkIcon /> Website
                  </>
                )}
              </a>
            </div>
          </li>
        ))}
      </CreditList>
    </>
  )
}

const CreditList = styled.ul`
  list-style-type: none;
  margin: 60px 0 0;

  img {
    height: 70px;
    margin-right: 25px;
  }

  a {
    display: flex;
    align-items: center;
  }

  svg {
    height: 22px;
    margin-right: 5px;
    fill: ${colors.brand};
  }

  li {
    display: flex;
    margin-bottom: 15px;
  }

  h3 {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    column-count: 2;

    li {
      margin-bottom: 35px;
    }
  }
`

export default Credits
