import React from "react"
import styled from "@emotion/styled"

import Section from "../shared/Section"
import { breakpoints } from "../../utils/styles"

const newLangs = [
  "Tamil",
  "Thai",
  "Nepali",
  "Javanese",
  "Khmer",
  "Bengali",
  "Punjabi",
  "Urdu",
  "Latvian",
  "Kurdish",
  "Malay",
  "Bosnian",
  "Bulgarian",
  "Belarusian",
  "Slovak",
  "Slovenian",
  "Armenian",
]

const Roadmap = () => (
  <>
    <Section first={true}>
      <h2>Project Roadmap</h2>
      <Version>Current: v1.0.2</Version>
    </Section>
    <Grid>
      <div>
        <h3>Features</h3>
        <FeatureList>
          <li>Search by country</li>
          <li>Alternate translations</li>
          <li>Male and female context</li>
          <li>Human voice recordings</li>
        </FeatureList>
        <h3>Improvements</h3>
        <FeatureList>
          <li>Better i18n support</li>
          <li>
            Replace Airtable embeds with native forms and Lambda functions
          </li>
          <li>On-page corrections / edits</li>
        </FeatureList>
      </div>
      <div>
        <LanguageList>
          <h3>Upcoming Locales</h3>
          <ul>
            {newLangs.map(lang => (
              <li key={lang}>{lang}</li>
            ))}
          </ul>
        </LanguageList>
      </div>
    </Grid>
  </>
)

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;

  @media (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 0.6fr 0.4fr;
  }
`

const FeatureList = styled.ul`
  font-size: 1.2em;
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.35em;
  }
`

const LanguageList = styled.div`
  border: 1px solid gainsboro;
  border-radius: 3px;
  overflow: hidden;

  h3 {
    background: #fbfbfb;
    border-bottom: 1px solid gainsboro;
    padding: 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    column-count: 2;
    padding: 0 20px 30px;
  }
`

const Version = styled.div`
  margin: -30px 0 40px;
  color: #757575;
  font-size: 1.1em;
`

export default Roadmap
