import React from "react"
import { Link } from "gatsby"

import Section from "../shared/Section"

const Contributors = () => (
  <>
    <Section first={true}>
      <h2>Contributors</h2>
      <p>
        This site would be useless without the help of local speakers,
        polyglots, and passionate linguists who correct mistakes and contribute
        their ideas.
      </p>
      <p>
        If you're interested in joining this list, submit an alternate
        translation or correct an error with{" "}
        <Link to="/feedback">this form</Link>. You can also help out with any of
        the Study Guide websites.
      </p>
    </Section>
    <Section>
      <ul>
        <li>Giordana Pais</li>
        <li>Kaman Wan</li>
        <li>Lana Dalle</li>
        <li>Anil Bansal</li>
        <li>Dominic Lenke</li>
        <li>Sophie Van Der Meulen</li>
        <li>Kim Ruf</li>
        <li>George Nguyen</li>
        <li>Rebecca Diego</li>
        <li>Jenah Mayzes</li>
        <li>Hanne Ruona</li>
        <li>Inhyun Paek</li>
        <li>Marko Gadzinksi</li>
        <li>Ariel Miller</li>
        <li>Wasken Akkaya</li>
        <li>Ryusuke Munakata</li>
      </ul>
    </Section>
  </>
)

export default Contributors
