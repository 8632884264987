import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import { breakpoints } from "../../utils/styles"

const Section = ({ children, first }) => (
  <SectionWrapper first={first}>{children}</SectionWrapper>
)

const SectionWrapper = styled.section`
  p {
    font-size: 1.2em;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 1.85em;
    margin: ${({ first }) => (first ? `0 0 40px` : `60px 0 40px`)};
  }

  .app-link {
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }

  ul {
    column-count: 2;
    list-style-type: none;
    font-size: 1.2em;
    margin: 50px 0 0;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    p {
      font-size: 1.4em;
      margin-bottom: 40px;
    }

    h2 {
      font-size: 2.3em;
      margin: ${({ first }) => (first ? `0 0 50px` : `80px 0 50px`)};
    }

    ul {
      font-size: 1.35em;
    }
  }
`

Section.propTypes = {
  first: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Section
