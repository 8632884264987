import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import { colors, breakpoints } from "../../utils/styles"
import Section from "../shared/Section"

const About = () => (
  <>
    <MissionStatement>
      <div className="heading">Mission</div>
      To help you overcome language barriers, no matter the destination of your
      next trip.
    </MissionStatement>
    <Section>
      <h2>Just the Key Ingredients</h2>
      <p>
        On a recent trip to Japan, I explored a completely unfamiliar culture
        with no knowledge of Japanese. I wanted to be respectful to the locals,
        so I found myself repeatedly searching for translations like{" "}
        <strong>please</strong> and <strong>thank you</strong>.
      </p>
      <p>
        This problem isn't limited to my Japanese adventure—it happens anytime I
        travel somewhere new.
      </p>
      <p>
        I've tried a few great services like Duolingo, Babbel, and Rosetta
        Stone, but their goals are the same: to develop language proficiency.
      </p>
      <p>
        What if you just need to prep for a quick vacation? Or a work trip? In
        these cases, your time is limited and you only need to grasp the
        essentials.
      </p>
      <p>
        <Link to="/">Language Guide</Link> is my attempt to curate the most
        basic, universal list of words and phrases for navigating a new locale.
      </p>
      <p>
        Try using it on your next trip to make your experience more meaningful!
      </p>
    </Section>
    <Section>
      <h2>A Team Effort</h2>
      <p>
        Context is a critical piece of communication and there are many cultural
        factors that influence the correct phrase at the right time.
      </p>
      <p>
        If you see your native language with an innacurate translation or if you
        would like to suggest an alternative, please let me know by{" "}
        <Link to="/feedback">making an edit here</Link>.
      </p>
      <p>
        This site becomes much more useful when the community corrects the
        course.
      </p>
      <p>
        You can also reach me on Twitter{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/mkdarshay"
        >
          @mkdarshay
        </a>
      </p>
    </Section>
  </>
)

const MissionStatement = styled.div`
  font-size: 1.65em;
  border: 1px solid gainsboro;
  padding: 20px 20px 35px;
  background: #fbfbfb;
  margin-bottom: 40px;
  border-radius: 3px;

  .heading {
    text-transform: uppercase;
    color: ${colors.brand};
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 30px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 2em;
    padding: 30px 30px 50px;
  }
`

export default About
