import React, { useState } from "react"
import styled from "@emotion/styled"
import { useTransition, animated as a } from "react-spring"

import PageContent from "../components/shared/PageContent"
import SEO from "../components/SEO"
import {
  Contributors,
  Credits,
  About,
  Roadmap,
  StudyGuide,
} from "../components/About"
import TransError from "../components/shared/TransError"
import { breakpoints, colors } from "../utils/styles"

const links = [
  { label: "About", component: <About /> },
  { label: "Study Guides", component: <StudyGuide /> },
  { label: "Roadmap", component: <Roadmap /> },
  { label: "Contributors", component: <Contributors /> },
  { label: "Credits", component: <Credits /> },
]

const AboutPage = () => {
  const [activeModule, setModule] = useState(links[0])
  const [fade, setFade] = useState(false)

  const transitions = useTransition(!fade, null, {
    from: { opacity: 0, transform: `translateY(-20px)` },
    enter: { opacity: 1, transform: `translateY(0)` },
    leave: { opacity: 0, transform: `translateY(20px)` },
    // unique: true,
    confg: { friction: 5 },
  })

  const fadeAnimation = link => {
    setFade(true)
    setTimeout(() => {
      setModule(link)
      setFade(false)
    }, 600)
  }

  return (
    <>
      <SEO title="About" />
      <TransError />
      <PageContent>
        <AboutGrid>
          <SideBar>
            {links.map(link => (
              <button
                key={link.label}
                className={
                  activeModule.label === link.label
                    ? "active naked-btn"
                    : "naked-btn"
                }
                onClick={() => fadeAnimation(link)}
              >
                {link.label}
              </button>
            ))}
          </SideBar>
          <Body>
            {transitions.map(
              ({ item, key, props }) =>
                item && (
                  <a.div key={key} style={props}>
                    {activeModule.component}
                  </a.div>
                )
            )}
          </Body>
        </AboutGrid>
      </PageContent>
    </>
  )
}

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;

  @media (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 0.2fr 0.8fr;
  }
`

const SideBar = styled.div`
  border-bottom: 1px solid gainsboro;
  line-height: 1.9;
  font-size: 1.2em;

  .active {
    font-weight: bold;
    color: ${colors.brand};
  }

  button:hover {
    color: ${colors.brand};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    border-right: 1px solid gainsboro;
    border-bottom: none;
  }
`

const Body = styled.div``

export default AboutPage

// More langs: Serbian, Bosnian, Bulgarian, Belarusian, Slovak, Slovenian, Armenian, Khmer, Nepali, Javanese, Bengali, Punjabi, Urdu, Latvian, Kurdish, Malay
